<template>
	<v-app class="fs_main images">
		
		<v-container>
			<v-row>
				<v-col>
					<div class="fs_main_header">
						<h1>{{ projectName }}</h1>
						<!-- <v-btn class="edit"></v-btn> -->
						<span class="split">
							<h2><span class="id">{{ placeId }}</span>{{ placeName }}</h2>
						</span>
					</div>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col>
					<header class="fs_block_header">{{ cameraName }} タイムラプス</header>
					<div class="image-navi">
						<div class="time_display"></div>

						<!-- <v-btn class="download">ダウンロード</v-btn> -->
						<datetime v-model="datetime" type="datetime" input-id="startDate" @close="dateUpdated" :minute-step="30" :auto="true"  >
							<label slot="before" for="startDate">開始日時変更</label>
						</datetime>

						<span class="slider_display">
							<v-slider label="再生速度" v-model="slider" max="15" min="2"></v-slider>
							<v-checkbox v-model="res" color="white" dark label="高画質 (ロードに時間がかかります)"></v-checkbox>
						</span>
					</div>
					
					<div class="fs_timelapse">
						
						<template>
							<vue-perfectlooper :class="timelapseClass" v-if="loadFlag" v-bind="loop"></vue-perfectlooper>
						</template>
						
					</div>
				</v-col>
			</v-row>

			
		</v-container>
		<v-footer >
			©2021 <a href="https://www.vegetalia.co.jp/" >vegetalia</a>, inc.
		</v-footer>
		
	</v-app>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueAuthImage from "vue-auth-image";
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VuePerfectlooper from 'vue-perfectlooper';
import isMobile from "ismobilejs";

Vue.use(VueAuthImage);

export default {
	computed:{
		slider:{
			get(){
				return this.sliderVal
			},
			set(newVal){
				this.sliderVal = newVal;
				this.loadFlag = false;
				this.loadCount++;
				this.$set(this.loop, 'fps', newVal);
				this.$set(this.loop, 'id', "fs_timelapse" + this.loadCount)
				this.$set(this.loop, 'fps', newVal)
				this.$set(this.loop, 'poster', this.loop.poster)
				this.$set(this.loop, 'src', this.loop.src);
				this.$nextTick(() => (this.loadFlag = true));
			}
		}
	},
	components: {
		datetime : Datetime,
		VuePerfectlooper
	},
	mounted() {
		this.is_mobile = isMobile(window.navigator);
		if (this.is_mobile.any) {
			this.timelapseClass = "mobile_timelapse";
		}
		this.showLoading();
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		axios
			.get(
				"https://" +
					this.config.defaultOriginString +
					"/api/photo-list?timelapse=true&cid=" +
					this.cid +
					"&n=30&type=thumb",
				{
					headers: {
						Authorization: "Bearer " + this.$store.state.auth.data.token,
					},
				}
			)
			.then((res) => {
				// 成功した場合
				if (res && res.data && res.data.status == "success") {
					this.$set(this.loop, 'poster', res.data.list[0].url)
					let array = [];
					let str = "";
					for (var i = 0; i < res.data.list.length; i++) {
						array.push(res.data.list[i].url);
						str += "'" + res.data.list[i].url + "', "
					}
					this.$set(this.loop, 'src', array);
					this.$nextTick(() => (this.loadFlag = true));
					this.hideLoading();
					// console.log("Success: " + JSON.stringify(res.data));
					this.updateTimeDisplay()
				} else {
					this.hideLoading();
					console.log("Fail: " + JSON.stringify(res));
				}
			})
			.catch((error) => {
				this.hideLoading();
				console.log(error);
			});
	},
	methods: {
		convertDate: function (n) {
			return (
				n.substring(0, 4) +
				"-" +
				n.substring(4, 6) +
				"-" +
				n.substring(6, 8) +
				" " +
				n.substring(8, 10) +
				":" +
				n.substring(10, 12) // +
				// 				":" +
				// 				n.substring(12, 14)
			);
		},
		displayLatest: function (key) {
			if (key == 0) return "最新";
			else return "";
		},
		dateUpdated: function () {
			if (this.datetime != this.last_datetime){
				this.loadCount++;
				this.loop = null;
				this.loadFlag = false;
				this.showLoading();
				let type = "thumb";
				if (this.res) type = "raw";
				axios
					.get(
						"https://" +
							this.config.defaultOriginString +
							"/api/photo-list?timelapse=true&type=" + type + "&cid=" +
							this.cid +
							"&n=30" +
							"&d=" +
							this.dateFormat(new Date(this.datetime), "yyyyMMddhhmmss"),
						{
							headers: {
								Authorization: "Bearer " + this.$store.state.auth.data.token,
							},
						}
					)
					.then((res) => {
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							// console.log("Success: " + JSON.stringify(res.data));
							this.loop = {};
							this.$set(this.loop, 'id', "fs_timelapse" + this.loadCount)
							this.$set(this.loop, 'fps', this.sliderVal)
							this.$set(this.loop, 'poster', res.data.list[0].url)
							let array = [];
							let str = "";
							for (var i = 0; i < res.data.list.length; i++) {
								array.push(res.data.list[i].url);
								str += "'" + res.data.list[i].url + "', "
							}
							this.$set(this.loop, 'src', array);
							this.$nextTick(() => (this.loadFlag = true));
							this.hideLoading();
						
							this.updateTimeDisplay(new Date(this.datetime))
							this.last_datetime = this.datetime
						} else {
							this.hideLoading();
							console.log("Fail: " + JSON.stringify(res));
						}
					})
					.catch((error) => {
						this.hideLoading();
						console.log(error);
					});
			}
			
		},
		dateFormat: function (date, format) {
			let _fmt = {
				hh: function (date) {
					return ("0" + date.getHours()).slice(-2);
				},
				h: function (date) {
					return date.getHours();
				},
				mm: function (date) {
					return ("0" + date.getMinutes()).slice(-2);
				},
				m: function (date) {
					return date.getMinutes();
				},
				ss: function (date) {
					return ("0" + date.getSeconds()).slice(-2);
				},
				dd: function (date) {
					return ("0" + date.getDate()).slice(-2);
				},
				d: function (date) {
					return date.getDate();
				},
				s: function (date) {
					return date.getSeconds();
				},
				yyyy: function (date) {
					return date.getFullYear() + "";
				},
				yy: function (date) {
					return date.getYear() + "";
				},
				t: function (date) {
					return date.getDate() <= 3
						? ["st", "nd", "rd"][date.getDate() - 1]
						: "th";
				},
				w: function (date) {
					return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][
						date.getDay()
					];
				},
				MMMM: function (date) {
					return [
						"January",
						"February",
						"$arch",
						"April",
						"$ay",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December",
					][date.getMonth()];
				},
				MMM: function (date) {
					return [
						"Jan",
						"Feb",
						"$ar",
						"Apr",
						"$ay",
						"Jun",
						"Jly",
						"Aug",
						"Spt",
						"Oct",
						"Nov",
						"Dec",
					][date.getMonth()];
				},
				MM: function (date) {
					return ("0" + (date.getMonth() + 1)).slice(-2);
				},
				M: function (date) {
					return date.getMonth() + 1;
				},
				$: function (date) {
					return "M";
				},
			};
			let _priority = [
				"hh",
				"h",
				"mm",
				"m",
				"ss",
				"dd",
				"d",
				"s",
				"yyyy",
				"yy",
				"t",
				"w",
				"MMMM",
				"MMM",
				"MM",
				"M",
				"$",
			];
			return _priority.reduce(
				(res, fmt) => res.replace(fmt, _fmt[fmt](date)),
				format
			);
		},
		updateTimeDisplay: function(t){
			if(t){
				document.querySelector('.time_display').innerHTML = this.dateFormat(t, "yyyy-MM-dd hh:mm") + ' から30件 (動画を除く)'
			} else {
				document.querySelector('.time_display').innerHTML = '最新の30件 (動画を除く)'
			}
			
		},
		getPlaceInfo: function(){
			let placeObject = {}
			for (let place of this.$store.state.places.data){
				for (let camera of place.cameras){
					if(camera.id == this.$route.params.id){
						placeObject.id =  place.id
						placeObject.name = place.name
						placeObject.camName = camera.name
					}
				}
			}
			return placeObject
		},
		showLoading : function(){
			this.$emit('loadShowHide',true);
		},
		hideLoading : function(){
			this.$emit('loadShowHide',false);
		}

	},
	data() {
		return {
			is_mobile: null,
			timelapseClass: "normal_timelapse",
			datetime: "",
			cid: this.$route.params.id,
			projectName : this.$store.state.project.data.name,
			placeId : ("000" + this.getPlaceInfo().id ).slice(-3),
			placeName : this.getPlaceInfo().name,
			cameraName  : this.getPlaceInfo().camName,
			last_datetime : 0,
			loadFlag: false,
			sliderVal: 5,
			loadCount: 0,
			res: false,
			loop: {
				"id": "fs_timelapse",
				"fps": 5,
				"poster": "",
				"src": []
			}
		};
	},
};
</script>

<style>
.mobile_timelapse {
	width: 96%; height: 96%; margin: auto; margin-top: 2%; margin-left: 2%; margin-right: 2%;
}
.normal_timelapse {
	width: 50%; height: 50%; margin-top: 10px;
}
</style>